import React, { Component } from 'react';
import './LegalLandAppSub.css'
class LegalLandAppSub1 extends Component{
    render() {
        return <><div id="table1"><table id="format" border="0">
        <tbody>
            <tr>
                <td id="text_format">
                    <span id="text1">
                        擬分割圖繪製欄（比例尺應與地籍圖相同，建築物著紅色，法定空地著綠色，現有空地著草綠色，分割線應加以標示。本欄不敷使用時，應以同規格另紙繪製）
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    </div></>
    }
}
export default LegalLandAppSub1